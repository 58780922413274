.App {
  height: 100%;
}

.App-logo {
  height: 70px;
  margin: 7px 14px 7px 7px;
  float: left;
}

.App-server-error {
  height: 30px;
  padding-top: 20px;
  padding-right: 20px;
  float: left;
  cursor: pointer;
  animation: flashing-error 1s linear infinite;
}

.App-server-error:hover {
  animation: none;
}

@keyframes flashing-error {
  50% {
    opacity: 30%;
  }
}

.App-header {
  background-color: #203764;
  height: 80px;
  padding: 10px;
  color: white;
}

.App-title {
  height: 80px;
  line-height: 80px;
  font-weight: bold;
  font-size: 1.5em;
  float: left;
}

.App-reset-refresh {
  line-height: 80px;
  float: right;
  padding-top: 5px;
  padding-right: 10px;
  cursor: pointer;
}

.App-refresh-icon {
  width: 20px;
}

.App-refresh-icon-spin {
  width: 20px;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-program-name {
  height: 80px;
  padding-right: 20px;
  line-height: 80px;
  float: right;
}

.App-program-logo {
  height: 20px;
  padding-top: 30px;
}

/* Style page content */
.main-content-open {
  animation-fill-mode: forwards;
  animation-name: main-content-open;
  animation-duration: 0.5s;
  height: 100%;
}

@keyframes main-content-open {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: 160px;
  }
}

.main-content-close {
  animation-fill-mode: forwards;
  animation-name: main-content-close;
  animation-duration: 0.5s;
  height: 100%;
}

@keyframes main-content-close {
  from {
    margin-left: 160px;
  }
  to {
    margin-left: 0px;
  }
}

.body-content {
  background-color: white;
  z-index: 10;
  overflow-y: hidden;
  margin-bottom: 300px;
  box-shadow: 0px 0px 30px #000000;
  height: calc(100% - 30px);
  padding: 20px;
  background-clip: padding-box;
}

@media screen and (min-width: 1500px) {
  .body-content {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

/* The MainMenu menu */
.MainMenu {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  direction: rtl;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track-piece {
  background: #d4d4d4;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

.content {
  direction: ltr;
}

.MainMenu-open {
  animation-fill-mode: forwards;
  animation-name: mainmenu-open;
  animation-duration: 0.5s;
}

@keyframes mainmenu-open {
  from {
    width: 0px;
  }
  to {
    width: 160px;
  }
}

.MainMenu-close {
  animation-fill-mode: forwards;
  animation-name: mainmenu-close;
  animation-duration: 0.5s;
}

@keyframes mainmenu-close {
  from {
    width: 160px;
  }
  to {
    width: 0px;
  }
}

/* The navigation menu links */
.MainMenu span {
  padding: 2px 2px 2px 5px;
  text-decoration: none;
  color: #818181;
  display: block;
  user-select: none;
}

/* When you mouse over the navigation links, change their color */
.MainMenu span:hover {
  color: #f1f1f1;
  cursor: pointer;
}

.hamburger {
  float: left;
  margin: 15px;
}

.hamburger .line {
  width: 40px;
  height: 4px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-10 {
  transition: all 0.3s ease-in-out;
}

#hamburger-10.is-active {
  transform: rotate(90deg);
}

#hamburger-10.is-active .line {
  width: 30px;
}

#hamburger-10.is-active .line:nth-child(2) {
  width: 40px;
}

.react-sanfona-item-title {
  background-color: #fafafa;
  border-top: 1px solid #ccc;
  color: #333;
  padding: 20px 20px 20px 10px;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.react-sanfona-item:first-child .react-sanfona-item-title {
  border-top: none;
}

.react-sanfona-item-expanded .react-sanfona-item-title {
  background-color: #000;
  color: #fff;
}

.react-sanfona-item-body-wrapper {
  color: #999;
  position: relative;
}

.react-sanfona-item-body-wrapper:hover .tooltip {
  opacity: 1;
}
