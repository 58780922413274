.product .productHeader {
  background-color: lightgray;
  padding: 1em;
  margin-bottom: 1em;
}

.product .errorMessage {
  color: red;
}

.product .productLineNum {
  margin: 0 0 10px 0;
  font-weight: normal;
}

.product .floatLeft {
  float: left;
  padding-right: 1em;
  padding-top: 1em;
}

.product .productDetailTable {
  border-collapse: collapse;
}

.product .productDetailTable td,
th {
  border: 1px solid #000;
  padding: 8px;
}

.product .editable:hover {
  background-color: #cccccc;
  cursor: pointer;
}

.product .productDetailTable th {
  font-weight: normal;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.product .locations {
  table-layout: fixed;
  width: 100%;
}

.product .locations th {
  border-bottom: 1px solid #000 !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.product .addRow {
  color: #fff;
  background-color: #203764;
  column-span: all;
}

.product .addRow:hover {
  background-color: #cccccc;
  cursor: pointer;
}
