.customer .header {
  background-color: lightgray;
  padding: 1em;
  margin-bottom: 1em;
}

.customer .numberName {
  margin: 0 0 10px 0;
  font-weight: normal;
}

.customer .floatLeft {
  float: left;
  padding-right: 2em;
}

.customer .detailTable {
  border-collapse: collapse;
}

.customer .detailTable td,
th {
  border: 1px solid #000;
  text-align: left;
  padding: 5px;
}

.customer .detailTable th {
  font-weight: normal;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
