.searchResults {
  border-collapse: collapse;
  width: 100%;
}

.searchResults th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.searchResults td,
th {
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
}

.searchResults tbody > tr:nth-child(even) {
  background-color: #f6c155a1;
}

.searchResults tbody:nth-child(even) {
  background-color: #eee;
}

.searchResults .row:hover {
  background-color: #cccccc;
  cursor: pointer;
}

.searchResults .columnHover td:hover {
  background-color: #bbbbbb;
}
