input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

input[type="checkbox"]:after {
  line-height: 1.5em;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 0);
  border-radius: 0.25em;
  transition: 0.2s;
}

input[type="checkbox"]:disabled:after {
  cursor: default;
}

input[type="checkbox"]:active:after {
  transform: scale(0);
}
